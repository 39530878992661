import React from 'react';
import './Openhouse.css';
import Footerlower from '../../../Footerlower/LowerFooter';
import Offcanvas from '../../../Offcanvas/Offcanvas';

import img1 from '../../../../AssetsforGallery/1.jpg';
import img2 from '../../../../AssetsforGallery/2.jpg';
import img3 from '../../../../AssetsforGallery/3.jpg';
import img4 from '../../../../AssetsforGallery/4.jpg';
import img5 from '../../../../AssetsforGallery/5.jpg';
import img6 from '../../../../AssetsforGallery/6.jpg';
import img7 from '../../../../AssetsforGallery/7.jpg';
import img8 from '../../../../AssetsforGallery/8.jpg';
import img9 from '../../../../AssetsforGallery/9.jpg';
import img10 from '../../../../AssetsforGallery/10.jpg';
import img11 from '../../../../AssetsforGallery/11.jpg';
import img12 from '../../../../AssetsforGallery/12.jpg';
import img13 from '../../../../AssetsforGallery/13.jpg';
import knslogo from '../../../../AssetsforGallery/6.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/autoplay'; 
import 'swiper/css/navigation';
import Patti from '../../../Patti/Patti';

const Openhouse = () => {
    return (
        <div>
            <Offcanvas></Offcanvas>
            <Patti></Patti>
            <div className='container-fluid bg-colour  p-3 text-center '>

           
            <h2 className='text-light my-5'>Projects</h2>

             <div className='container  '> 

                                                                
                    <div className='row '>

                    <Swiper
                    modules={[Navigation, Autoplay]}
                    autoplay={true}
                    breakpoints={{
                        0:{
                        spaceBetween:10,      
                        slidesPerView: 1,
                        },
                        480: {
                        spaceBetween:10,
                        slidesPerView: 2,
                        },
                        768: {
                        spaceBetween:15,
                        slidesPerView: 2,
                        },
                        1024: {
                        spaceBetween:30,
                        slidesPerView:4,
                        },
                    }}
                    // navigation
                    pagination={{ clickable: true }}
                    

                    >
                                <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img1}
                                                class="w-100  px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Anirvan</h1>
                                            
                                            </div>

                                </SwiperSlide>


                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img2}
                                                class="w-100  px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Athena</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img3}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Ethos</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img4}
                                                class="w-100  px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Nester</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img5}
                                                class="w-100  px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Oasis</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img6}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Ojas</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img7}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Ethos</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img8}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Athena</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img9}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Anirvan</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img10}
                                                class="w-100  px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Neoliva</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img11}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Neoliva</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img12}
                                                class=" w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Oasis</h1>
                                            
                                            </div>

                                </SwiperSlide>
                                   <SwiperSlide>                   

                                            {/* <img src={} class=" image-size p-3  " id='imgchange' alt="..."/>  */}
                                            <div className='text-center mb-5'>
                                            <img
                                                src={img13}
                                                class="w-100 px-2" 
                                                alt="Aloraimage"
                                            />
                                            
                                            <img src={knslogo} class=" image-size p-2 logo-width" alt="..."/> 
                                            <h1 className='fw-light font-size mb-4 text-light'> Ethos</h1>
                                            
                                            </div>

                                </SwiperSlide>

                        </Swiper>  
                        </div>   

                    </div>
                   
                    </div>
                    <Footerlower></Footerlower>
                            </div>
    );
};

export default Openhouse;