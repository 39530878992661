import React, { useState, useEffect } from 'react';
import './AmoraLocation.css';
import aloramap from '../../../../../../../AssetsforIndividualProject/Amoraimages/amoramap.webp';
import factsheet from '../../../../../../../AssetsforIndividualProject/Amoraimages/factsheet.webp';
import aminities from '../../../../../../../AssetsforIndividualProject/Amoraimages/amenities.webp';
import rera from '../../../../../../../AssetsforIndividualProject/Amoraimages/rera.webp';
import AmoraDownload from "../../../../../../../AssetsforIndividualProject/Amoraimages/Amora_Brochure.pdf"
import aloradetails from '../../../../../../../AssetsforIndividualProject/Amoraimages/amoralogo.webp';

const AmoraLocation = () => {
    const [showtab, setshowtab] = useState(1);
    const [fileExists, setFileExists] = useState(false); // File existence initially unknown
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkFileExists = async () => {
            try {
                const url = "/home/sadanand/Documents/KNS-REACT-WEBSITE/KNS-WEBSITE/src/AssetsforIndividualProject/Amoraimages/dummy.pdf"; // Update with the actual path to your PDF file
                const response = await fetch(url, { method: 'HEAD' });
                if (response.ok) {
                    setFileExists(true); // File exists
                } else {
                    setFileExists(false); // File doesn't exist
                }
            } catch (error) {
                console.error('Error checking file existence:', error);
                setError(error);
            }
        };

        checkFileExists();
    }, []); // Run once when component mounts

    const handleDownload = () => {
        if (!fileExists) {
            alert("Sorry, the file is not available for download.");
        }
    };

    if (error) {
        return <div>Error checking file existence: {error.message}</div>;
    }





    const handleclick = (e) => {
        setshowtab(e);
    }



    return (
        <div className='container my-5'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <a href="https://maps.app.goo.gl/aJB7uw1DSg7QcNec9" target="_blank"><img src={aloramap} className='w-100' alt='' /></a>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 text-center my-5'>
                    <div className='row'>
                        <div className='col-12 '>
                            <div class="btn-group flexdirect " role="group" aria-label="Basic example">
                                <button type="button" className={showtab === 1 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "} onClick={() => handleclick(1)}>FACTSHEET</button>
                                <button type="button" className={showtab === 2 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "} onClick={() => handleclick(2)}>AMENITIES</button>
                                <button type="button" className={showtab === 3 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "} onClick={() => handleclick(3)}>RERA</button>
                                {/* <a href={fileExists ? AmoraDownload : "#"} download className="button-color button-width me-4 mt-1" onClick={handleDownload} style={{ textAlign: 'center' }}>
                                    DOWNLOAD
                                </a> */}
                        <button type="button" className="button-color  button-width me-4 mt-1">DOWNLOAD</button>


                            </div>
                        </div>
                        <div >
                            {/* all details div here */}
                            <div className={showtab === 1 ? 'display-block' : 'display-none'}>
                                <img src={factsheet} className=' w-100 mt-5 ' alt='Factsheet' />
                            </div>
                            <div className={showtab === 2 ? 'display-block' : 'display-none'}>
                                <img src={aminities} className=' w-100 mt-5 ' alt='Factsheet' />
                            </div>
                            <div className={showtab === 3 ? 'display-block' : 'display-none'}>
                                <img src={rera} className=' w-100 mt-5 ' alt='Factsheet' />
                            </div>
                            {/* <div className={showtab === 4 ? 'display-block' : 'display-none'}>
                                <img src={aloradetails} className=' w-100 mt-5 ' alt='Factsheet' />
                                <h1>Contact us</h1>
                            </div> */}
                            {/* <div> This div 4</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmoraLocation;