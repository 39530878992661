import React, { useEffect, useState } from 'react';
import AboutUs from './Aboutus/AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Homeone.css';

// import modaldesk from '../../AssetsforProjects/notification desk.webp';
// import modalmob from '../../AssetsforProjects/notification mob.webp';
// import newlaunchgif from'../../AssetsforProjects/newlaunch.gif';

import Home from './Home/Home';
import Knslocation from './KnsLocation/Knslocation';

import Location from './Projects/Location';
import Testimonial from './Testimonial/Testimonial';
import Footerlower from '../Footerlower/LowerFooter';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import FromFillup from './Form/FromFillup';

// import { Link } from 'react-router-dom';

const Homeone = () => {
    // const [showModal, setShowModal] = useState(true);

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[]);


    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setShowModal(false);
    //     }, 10000);
      
    //     return () => clearTimeout(timeout);
    //   }, []);
      

    return (
        <div>
            <Helmet>
                <title>Sites and Plots for sale in Bangalore & Mysore | KNS Group</title>
                <meta name='description' content='Plots for Sale in Bangalore and Mysore | KNS Infrastructure offers BDA, BIAPPA, MUDA and BMRDA approved premium sites, villa & Gated Community plots'/>
                <link rel="canonical" href="https://knsgroup.in"/>
                
            </Helmet>


                {/* Modal is here */}
                {/* <div className="modal mt-5" tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog " role="document">
                    <div className="modal-content ">
                    
                    <div className="modal-body d-flex justify-content-center ">
                    
                        <div>
                            <img src={newlaunchgif} className='w-25 position-absolute top-0 start-0' alt='new launch gif'/>
                        <Link to='/alora'><img src={modaldesk} className='w-100 p-2 desk-view' alt='new launch img'/></Link>
                        <Link to='/alora'><img src={modalmob} className='h-100 p-2 mob-view' alt='new launch img'/></Link>
                        </div>
                        <button type="button" className="btn-close position-absolute top-0 end-0 "  onClick={() => setShowModal(false)}></button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
                    </div>
                    </div>
                </div>
                </div> */}



            <Home></Home>
            <FromFillup></FromFillup>
            <AboutUs></AboutUs>
            <Location></Location>
            <Testimonial></Testimonial>
            <Knslocation></Knslocation>
           <Footerlower></Footerlower>
        </div>
    );
};

export default Homeone;