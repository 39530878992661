import React from 'react';
import undermain from '../../AssetsIcon/undermaintenance.webp';
import Offcanvas from '../Offcanvas/Offcanvas';
import LowerFooter from '../Footerlower/LowerFooter';
import'./Undermain.css';

const Undermain = () => {
    return (
        <div>
            <Offcanvas></Offcanvas>
            <img className='w-100 h-100 margin-under' src={undermain} alt='under maintenance'/>
            <LowerFooter></LowerFooter>
        </div>
    );
};

export default Undermain;