import React from 'react';
import happyimg from '../../Assets/love.gif';
import logoimg from '../../Assets/11.webp';
import allrightsimg from '../../Assets/12.png';
import './LowerFooter.css';
import { Link } from 'react-router-dom';
import fac from '../../AssetsIcon/fb.png';
import goo from '../../AssetsIcon/google.png';
import ins from '../../AssetsIcon/insta.png';
import link from '../../AssetsIcon/link.png';
import twi from '../../AssetsIcon/twitter.png';




const LowerFooter = () => {



    return (
        <div>
            <div className='row bgcolor'>
                {/* left side here */}
                <div className='col-lg-12 col-md-12 col-sm-12 marign-top' >

                    <div className='row pe-3 margin-pataka'>
                        <div className='col-12 d-flex justify-content-center'>
                            <Link className="nav-link p-2 active ps-3 py-1 ms-2 fot-menu" aria-current="page" to='/'>HOME</Link>
                            <Link className="nav-link p-2 ps-1 py-1 fot-menu" to='/about'>ABOUT</Link>
                            <Link className="nav-link p-2 ps-1 py-1 fot-menu" to='/projects'>PROJECT</Link>
                            <Link className="nav-link p-2 ps-1 py-1 fot-menu" to='/gallery'>GALLERY</Link>
                            <Link className="nav-link p-2 ps-1 py-1 fot-menu" to='/undermaintenance'>CAREERS</Link>
                            <Link className="nav-link p-2 ps-1 py-1 fot-menu me-2" to='/blogs'>BLOGS</Link>
                        </div>
                    </div>


                    <div className='row margin-dhamaka pe-3'>
                        <div className='d-flex justify-content-center '>

                            <Link class="nav-link ms-2 fw-bold fot-menu" to='/allprojectsbengaluru'>BENGALURU</Link>

                            <Link class="nav-link ms-2 fw-bold fot-menu" to='/allprojectsmysuru'>MYSURU</Link>
                        </div>
                    </div>

                    <div className='row pe-3'>
                        <div className='d-flex justify-content-center margin-set'>
                            {/* <img className="width-copy" src={allrightsimg} alt='copyrightreserve' />   */}
                            <p className='text-center mt-3'>&copy; All rights reserved to <br />
                                KNS INFRASTRUCTURE PRIVATE LIMITED, BENGALORE- 560104</p>
                        </div>

                        <div className='d-flex justify-content-center text-end margin-set mb-1' >
                            <h6 className='text-sizing'>PLEASE READ THE <Link to='/disclaimer' className='an-color'>  DISCLAIMER</Link> AND <Link to='/privacy' className='an-color'> PRIVACY POLICY </Link></h6>
                        </div>

                    </div>
                    <div className='row '>
                        <div className='d-flex justify-content-center '>
                            <a className='' href="https://www.facebook.com/knsinfrastructures" target="_blank"><img src={fac} alt='facebook Icon' /></a>
                            <a className='width-icon' href="https://goo.gl/maps/WwyrfavP6ySnw5gy5" target="_blank"><img src={goo} alt='facebook Icon' /></a>
                            <a className='width-icon' href="https://www.instagram.com/kns_infrastructure/" target="_blank"><img src={ins} alt='facebook Icon' /></a>
                            <a className='width-icon' href="https://www.linkedin.com/company/kns-infrastructure/" target="_blank"><img src={link} alt='facebook Icon' /></a>
                            <a className='width-icon pe-5' href="https://twitter.com/kns_infra" target="_blank"><img src={twi} alt='facebook Icon' /></a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default LowerFooter;