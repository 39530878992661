import React from 'react';
import testone from '../../../Assets/test 1.png';
import testtwo from '../../../Assets/test 2.png';
import testthree from '../../../Assets/test 3.png';
import testfour from '../../../Assets/test 4.png';


const Testimonial = () => {
    return (
        <div>
            <div class="container text-center">
            <div class="row g-2 my-5">
                <h2>TESTIMONIALS</h2>

                <div class="col-lg-3 col-md-6 col-sm-12 ">
                <img src={testone} className='w-100 p-3' alt='testimonial'/>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                <img src={testtwo} className='w-100  px-3 py-3' alt='testimonial'/>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                <img src={testthree}  className='w-100 px-3 py-3' alt='testimonial'/>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                <img src={testfour} className='w-100 p-3' alt='testimonial'/>
                </div>
                
                
            </div>
            </div>
        </div>
    );
};

export default Testimonial;