//refernce Alora
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import emailjs from "emailjs-com";
import './Amora.css';
import Offcanvas from '../../../../../Offcanvas/Offcanvas';
import Patti from '../../../../../Patti/Patti';
import Faqcalisto from '../../../Mysoreprojects/Allprojectmysore/Calisto/Faq/Faqcalisto';
import Footerlower from '../../../../../Footerlower/LowerFooter';
import aloradetails from '../../../../../../AssetsforIndividualProject/Amoraimages/amoralogo.webp';
import aloramain from '../../../../../../AssetsforIndividualProject/Amoraimages/amoramain.webp';
import AmoraLocation from './AmoraLocation/AmoraLocation';
import AmoraSlider from './AmoraSlider/AmoraSlider';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import backone from '../../../../../../Assets/back 1.jpg';
import backtwo from '../../../../../../Assets/back 2.jpg';
import backthree from '../../../../../../Assets/back 3.jpg';
import backonemob from '../../../../../../Assets/back 1 mob.jpg';
import backtwomob from '../../../../../../Assets/back 2 mob.jpg';
import backthreemob from '../../../../../../Assets/back 3 mob.jpg';
import backaadrika from '../../../../../../AssetsforProjects/aarika web back.webp';
import backaadrikamob from '../../../../../../AssetsforProjects/aarika web mob.webp';
import backathena2 from '../../../../../../AssetsforProjects/athenaback.webp';
import backathena2mob from '../../../../../../AssetsforProjects/athenabackmob.webp';
import backlaurel from '../../../../../../AssetsforIndividualProject/Laurel/laurelwebback.webp';
import backmoblaurel from '../../../../../../AssetsforIndividualProject/Laurel/laurelmobback.webp';
import backananta from '../../../../../../AssetsforIndividualProject/Laurel/webbackananta.webp';
import backmobananta from '../../../../../../AssetsforIndividualProject/Laurel/webbackanantamob.webp';
import backamora from '../../../../../../AssetsforIndividualProject/Amoraimages/amoradesktop.webp';
import backmoamora from '../../../../../../AssetsforIndividualProject/Amoraimages/amoramobile.webp';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

const Amora = () => {
    const navigate = useNavigate();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
    })
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, []);
    const sendEmail = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        if (formData.name && formData.email && formData.number) {
            emailjs.sendForm('service_v73c39b', 'template_5e93gnf', e.target, 'Q_tnUooVuTOT2eItg')
                .then((result) => {
                    window.location.href = 'https://thankyoupage.knsgroup.in/';
                    setFormData({
                        name: '',
                        email: '',
                        number: ''
                    });
                }, (error) => {
                    Swal.fire(
                        'Oops!',
                        'Somethings Wrong, Please Try Again',
                        'error'
                    );
                    setFormData({
                        name: '',
                        email: '',
                        number: ''
                    })
                });
            e.target.reset();
        }
        else {
            Swal.fire(
                'Oops!',
                'Please Fill the details',
                'info'
            );
        }
    };
    return (
        <div>
            <Swiper
                modules={[Navigation, Autoplay]}
                autoplay={true}
                breakpoints={{
                    0: {
                        spaceBetween: 10,
                        slidesPerView: 1,
                    },
                    480: {
                        spaceBetween: 10,
                        slidesPerView: 1,
                    },
                    768: {
                        spaceBetween: 15,
                        slidesPerView: 1,
                    },
                    1024: {
                        spaceBetween: 30,
                        slidesPerView: 1,
                    },
                }}
                // navigation
                pagination={{ clickable: true }}
                className='image-slider text-center'

            >
                <SwiperSlide>
                    <Link to='/'><img src={backamora} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/'><img src={backmoamora} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='/laurel'><img src={backlaurel} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/laurel'><img src={backmoblaurel} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='https://knsananta.com/'><img src={backananta} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='https://knsananta.com/'><img src={backmobananta} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>

                <SwiperSlide>
                    <Link to='/athena2'><img src={backathena2} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/athena2'><img src={backathena2mob} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='/aadrika'><img src={backaadrika} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/aadrika'><img src={backaadrikamob} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='/calisto'><img src={backone} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/calisto'><img src={backonemob} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='/aroha'><img src={backtwo} className='w-100 desk ' alt='main background' /></Link>
                    <Link to='/aroha'><img src={backtwomob} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to='/fresco'><img src={backthree} className='w-100 desk' alt='main background' /></Link>
                    <Link to='/fresco'><img src={backthreemob} className='w-100 mob' alt='main background' /></Link>
                </SwiperSlide>
            </Swiper>
            <Helmet>
                <title>KNS Amora Land & Sites for sale in Bangalore |Off Mysore Road </title>
                <meta name='description' content='Land for sale in Bangalore | KNS Alora Offers BMICAPA approved gated community plots for sale in Bangalore' />
                <link rel="canonical" href="https://knsgroup.in/alora" />
            </Helmet>
            <Offcanvas></Offcanvas>
            <Patti></Patti>
            <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-6 col-sm-12 order-last order-lg-first d-flex flex-column">
                        <div className='d-flex flex-column justify-content-center '>
                            <div className='text-center'>
                                <img src={aloradetails} className='w-25 top-mar mb-5' alt='amoralogo' />
                            </div>
                            <div>
                                <p className='fw-light ps-5 pe-5 py-2 mb-5 font-sizer'>
                                    "Amora" carries the enchanting meaning of love, <br />
                                    encapsulating the essence of affection and connection.<br /><br />
                                    The intention is to create a vibrant and inclusive environment that caters to the diverse needs of its residents. <br />
                                    and why not? Alora is a Great word for light.<br /><br />
                                    It’s not just about owning a piece of land;<br />
                                    it’s about embracing a lifestyle that embodies elegance, convenience, and opulence.<br /><br />
                                    The emphasis on aesthetics, functionality, and environmental sustainability makes
                                    KNS Amora a promising example of Upscale plotted development.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 order-first order-lg-last d-flex align-items-center">
                        <img className="w-100 margin-imager" src={aloramain} alt='atharvaimagedetails' />
                    </div>
                </div>
            </div>
            <div className='container p-3'>
                <form class="row g-3" onSubmit={sendEmail}>
                    <div class="col-md-3 col-sm-12">
                        <label for="inputEmail4" class="form-label" >Project Name</label>
                        <input type="text" class="form-control" id="inputEmail4" name='project_name' value='KNS AMORA' />
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label for="inputEmail4" class="form-label" >Name</label>
                        <input type="text" class="form-control" id="inputEmail4" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })} />
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label for="inputPassword4" class="form-label">Contact Number</label>
                        <input type="number" class="form-control hide-number-arrow" id="inputPassword4" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })} />
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label for="inputPassword4" class="form-label">Email</label>
                        <input type="email" class="form-control" id="inputPassword4" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })} />
                    </div>
                    <div className='col-6'>
                        <button type="submit" class="btn button-color my-5" disabled={isButtonDisabled}>Submit</button>
                    </div>
                </form>
            </div>
            <AmoraLocation></AmoraLocation>
            <Faqcalisto></Faqcalisto>
            <Footerlower></Footerlower>
        </div>
    );
};
export default Amora;