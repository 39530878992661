import React, { useState } from 'react';
import'./AtharvaLocation.css';
import atharvamap from '../../../../../../../AssetsforIndividualProject/Atharvaimages/atharvamap.png';
import factsheet from '../../../../../../../AssetsforProjects/atharvafactsheet.jpg';
import aminities from '../../../../../../../AssetsforProjects/atharvaamenities.jpg';
import rera from '../../../../../../../AssetsforProjects/atharvarera.jpg';

const AtharvaLocation = () => {

    const[showtab, setshowtab] = useState(1);


    const handleclick = (e)=>{
        setshowtab(e);
    }

    return (
        <div className='container my-5'> 
            <div className='row'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                   <a href="https://goo.gl/maps/CF37VG7Ap1N8NkvR9" target="_blank"><img src={atharvamap} className='w-100 mb-4' alt=''/></a>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 text-center mb-5'>
                <div className='row'>
                    <div className='col-12'>
                    <div class="btn-group flexdirect " role="group" aria-label="Basic example">
                        <button type="button" className={ showtab===1 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "} onClick={()=>handleclick(1)}>FACT SHEET</button>
                        <button type="button" className={ showtab===2 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "} onClick={()=>handleclick(2)}>AMENITIES</button>
                        <button type="button" className={ showtab===3 ? 'button-active button-width me-4 mt-1' : "button-color button-width me-4 mt-1 "}onClick={()=>handleclick(3)}>RERA</button>
                        <button type="button" className="button-color  button-width me-4 mt-1">DOWNLOAD</button>
                        </div>
                    </div>
                    <div>
                        {/* all details div here */}
                        <div className={showtab===1 ?'display-block':'display-none'}>
                            <img src={factsheet} className=' w-100 mt-5 ' alt='Factsheet'/>
                        </div>
                        <div className={showtab===2 ? 'display-block':'display-none'}> 
                            <img src={aminities} className=' w-100 mt-5 ' alt='Factsheet'/>
                        </div>
                        <div className={showtab===3 ?'display-block':'display-none'}> 
                            <img src={rera} className=' w-100 mt-5 ' alt='Factsheet'/>
                        </div>
                        {/* <div> This div 4</div> */}
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default AtharvaLocation;