import React from 'react';
// import Swiper core and required modules
import { Navigation, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import img1 from '../../../../../../../AssetsforIndividualProject/Aloraimages/alora1.webp';
import img2 from '../../../../../../../AssetsforIndividualProject/Aloraimages/alora2.webp';
import img3 from '../../../../../../../AssetsforIndividualProject/Aloraimages/alora3.webp';
import img4 from '../../../../../../../AssetsforIndividualProject/Aloraimages/alora4.webp';
import img5 from '../../../../../../../AssetsforIndividualProject/Aloraimages/alora5.webp';


const AloraSlider = () => {
    return (
        <div className='container my-5'>
            <Swiper
                modules={[Navigation, Autoplay]}
                autoplay={true}
                breakpoints={{
                    0:{
                    spaceBetween:10,      
                    slidesPerView: 1,
                    },
                    480: {
                    spaceBetween:10,
                    slidesPerView: 2,
                    },
                    768: {
                    spaceBetween:15,
                    slidesPerView: 2,
                    },
                    1024: {
                    spaceBetween:30,
                    slidesPerView:4,
                    },
                }}
                >
                <SwiperSlide>
                    <img className='w-100' src={img1} alt='atharva slideone'/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className='w-100' src={img2} alt='atharva slideone'/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className='w-100' src={img3} alt='atharva slideone'/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className='w-100' src={img4} alt='atharva slideone'/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className='w-100' src={img5} alt='atharva slideone'/>
                </SwiperSlide>
               
                
                
                </Swiper>
        </div>
    );
};

export default AloraSlider;