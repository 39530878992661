import React, { useEffect, useRef, useState } from 'react';
import Footerlower from '../../../../../Footerlower/LowerFooter';
import Faqcalisto from '../../../Mysoreprojects/Allprojectmysore/Calisto/Faq/Faqcalisto';
import './Aroha.css';
import ArohaLocation from './ArohaLocation/ArohaLocation';
import arohadetails from '../../../../../../AssetsforIndividualProject/Arohaimages/alora.webp';
import arohaimage from '../../../../../../AssetsforIndividualProject/Arohaimages/aroha 2.jpg';
import Offcanvas from '../../../../../Offcanvas/Offcanvas';
import emailjs from "emailjs-com";
import Patti from '../../../../../Patti/Patti';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';


const Aroha = () => {
    
    
    // const modalRef = useRef(null);

    // useEffect(() => {
    //   const timer = setInterval(() => {
    //     showModal();
    //   }, 8000);
  
    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);
  
    // const showModal = () => {
    //   const modalElement = modalRef.current;
    //   const modal = new Modal(modalElement);
    //   modal.show();
    // }




    const navigate = useNavigate();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',

    })
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname)
    },[]);

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
    
        if(formData.name && formData.email && formData.number){
            emailjs.sendForm('service_v73c39b', 'template_5e93gnf', e.target, 'Q_tnUooVuTOT2eItg')
            // emailjs.sendForm('service_z652qps', 'template_prp5ovi', e.target, 'BrVdjJ_dP2sCEqW45')
        .then((result) => {
            // alert('Thank You! We reach you soon!');
            // Swal.fire(
            //     'Thank You!',
            //     'We will reach you soon',
            //     'success'
            //   );

            // navigate('https://thankyoupage.knsgroup.in/'); 
            window.location.href = 'https://thankyoupage.knsgroup.in/';          

                  setFormData({
                    name: '',
                    email: '',
                    number: ''
                  });
        }, (error) => {
            // alert('Server Issus! Not Submitted');
            Swal.fire(
                'Oops!',
                'Somethings Wrong, Please Try Again',
                'error'
              );
              setFormData({
                name: '',
                email: '',
                number: ''
              });
        });
        e.target.reset();
        }
        else{
            Swal.fire(
                'Oops!',
                'Please Fill the details',
                'info'
              );
        }
      };

    return (
        <div>
             <Helmet>
                <title>BMRDA Approved Villa Plots for sale in Bangalore</title>
                <meta name='description' content='KNS Aroha is a residential real estate project that is situated in the heart of Bangalores Magadi economic corridor. We offers plots for sale in Bangalore'/>
                <link rel="canonical" href="https://knsgroup.in/aroha"/>
            </Helmet>
            
            <Offcanvas></Offcanvas>
            <Patti></Patti>
             <div class="container">
                <div class="row mt-5">
                    <div class="col-lg-6 col-sm-12 order-last order-lg-first d-flex flex-column">
                       <div className='d-flex flex-column justify-content-center text-center'>
                        <div>
                        <img src={arohadetails } className='w-25 my-5' alt='arohalogo'/>
                        </div>
                        <div>
                        <p className='fw-light mb-5'>
                            Nestled in the fast-developing Bangalore's Magadi economic corridor, this location is a sureshot mark of the next big thing in the residential real estate market, for its proximity to the highway and its demand for several commercial establishments, this will indefinitely set you on a path of financial growth and freedom.
                            </p>
                        </div>                         

                       </div>

                        {/* <div className='d-flex justify-content-center '>
                            <button type="button" class=" button-color mb-5  " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                            Schedule a visit
                            </button>


                                form modal here
                                <div class="modal fade" id="myModal" ref={modalRef} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog width-dialog">
                                            <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Reach You soon!</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body h-100">
                                               

                                            <form class="row g-3" onSubmit={sendEmail}>
                                                 <div class="col-md-12">
                                                        <label for="inputEmail4" class="form-label" >Project Name</label>
                                                        <input type="text" class="form-control" id="inputEmail4" name='project_name' value='KNS Aroha'/>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="inputEmail4" class="form-label" >Name</label>
                                                        <input type="text" class="form-control" id="inputEmail4" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })}/>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="inputPassword4" class="form-label">Contact Number</label>
                                                        <input type="text" class="form-control" id="inputPassword4" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })}/>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="inputPassword4" class="form-label">Email</label>
                                                        <input type="email" class="form-control" id="inputPassword4" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                                                    </div>
                                                    <button type="submit" class="btn button-color my-5 button-width " >Submit</button>
                                                    </form>



                                            </div>
                                         
                                            </div>
                                        </div>
                                        </div>

                        </div> */}

                    </div>
                    <div class="col-lg-6 col-sm-12 order-first order-lg-last d-flex align-items-center">
                        <img className="w-100" src={arohaimage} alt='atharvaimagedetails'/> 
                    </div>
                </div>               
            </div>

            <div className='container'> 
            <form class="row g-3 mt-3 " onSubmit={sendEmail}>
                                                 <div class="col-md-3">
                                                        <label for="inputEmail4" class="form-label" >Project Name</label>
                                                        <input type="text" class="form-control" id="inputEmail4" name='project_name' value='KNS AROHA'/>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="inputEmail4" class="form-label" >Name</label>
                                                        <input type="text" class="form-control" id="inputEmail4" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })}/>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="inputPassword4" class="form-label">Contact Number</label>
                                                        <input type="number" class="form-control hide-number-arrow" id="inputPassword4" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })}/>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="inputPassword4" class="form-label">Email</label>
                                                        <input type="email" class="form-control" id="inputPassword4" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })}/>
                                                    </div>
                                                    <div>
                                                    <button type="submit" class="btn button-color my-5" disabled={isButtonDisabled}>Submit</button>
                                                    </div>
                                                    </form>
            </div>
           <ArohaLocation></ArohaLocation>
            <Faqcalisto></Faqcalisto>
            <Footerlower></Footerlower>

        </div>
    );
};

export default Aroha;