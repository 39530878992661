import React from 'react';
import './Swippercalisto.css';



import swipone from '../../../../../../../AssetsforAllproject/c1.jpg';
import swiptwo from '../../../../../../../AssetsforAllproject/c2.jpg';
import swipthree from '../../../../../../../AssetsforAllproject/c3.jpg';
import swipfour from '../../../../../../../AssetsforAllproject/c4.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';






const Swippercalisto = () => {

  

    
    return (
        <div className='container p-3'>
                <div className='row'>
                <Swiper
                  modules={[Navigation, Autoplay]}
                  autoplay={true}
                breakpoints={{
                   0:{
                    spaceBetween:30,      
                    slidesPerView: 1,
                   },
                   480: {
                    spaceBetween:30,
                    slidesPerView: 2,
                   },
                   768: {
                    spaceBetween:30,
                    slidesPerView: 3,
                   },
                   1024: {
                    spaceBetween:30,
                    slidesPerView: 4,
                   },
                }}
                // navigation
                pagination={{ clickable: true }}
                >
                <SwiperSlide>
                    <img className="w-100 " src={swipone} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swiptwo} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipthree} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipfour} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipone} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swiptwo} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipthree} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-100 " src={swipfour} />
                </SwiperSlide>
                </Swiper>
                </div>
                {/* <div className='row d-flex justify-content-center mt-3'>
                            <button type="button" class=" button-color mb-5  w-25 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                            Schedule a visit
                            </button>
                </div> */}
        </div>
    );
};

export default Swippercalisto;