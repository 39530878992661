import React from 'react';
import './common.css';
import Offcanvas from '../Offcanvas/Offcanvas';
import Patti from '../Patti/Patti';
import LowerFooter from '../Footerlower/LowerFooter';

const Pri = () => {
    return (
        <div>
            <Offcanvas></Offcanvas>
            <Patti></Patti>
         <div className='conatiner my-5 p-5'>
            <h1 className='my-5'>Privacy</h1>
         <p className='text-alg'>
            This Privacy Policy is an agreement between KNS and its users, which outlines how KNS collects, uses, maintains, and discloses personal and non-personal identification information collected from users of its website and microsite. The policy states that KNS may collect personal identification information from users when they visit the website, subscribe to the newsletter, fill out a form, or engage in other activities, services, features, or resources on the site. Users may be asked to provide their name, email address, mailing address, and phone number, but they may choose to visit the site anonymously.<br/><br/>

            The policy also states that KNS may collect non-personal identification information from users when they interact with the site, such as the browser name, type of computer, and technical information about the user's connection to the site. KNS may use cookies to enhance user experience, but users may choose to refuse cookies or receive alerts when cookies are being sent.<br/><br/>

            KNS uses the collected information to improve customer service, personalize user experience, improve the site, run promotions, contests, surveys, and other site features, send users information they agreed to receive, and send periodic emails. KNS adopts appropriate data collection, storage, and processing practices and security measures to protect user information against unauthorized access, alteration, disclosure, or destruction.<br/><br/>

            KNS does not sell, trade, or rent user's personal identification information to others. KNS may share generic aggregated demographic information not linked to any personal identification information with subsidiaries, business partners, trusted affiliates, and advertisers for the purposes outlined above. KNS may update the privacy policy at its sole discretion, and users are advised to review the policy periodically.<br/><br/>

            By using the site, users accept the policy and agree to its terms. If users have any questions about the privacy policy or the site's practices, they can contact KNS in Bengaluru at 8880663344. The policy also applies to the information and data collected by KNS call centers.

            </p>
         </div>
         <LowerFooter>
         </LowerFooter>
        </div>
    );
};

export default Pri;