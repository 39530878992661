import React, { useState } from 'react';
import './FormFillup.css';

import emailjs from "emailjs-com";

import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const FromFillup = () => {


  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({

    projectname: '',
    name: '',
    email: '',
    number: '',

  })





  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (formData.projectname && formData.name && formData.email && formData.number) {
      emailjs.sendForm('service_v73c39b', 'template_1ohhfb9', e.target, 'Q_tnUooVuTOT2eItg')
        // emailjs.sendForm('service_z652qps', 'template_zw98f2n', e.target, 'BrVdjJ_dP2sCEqW45')
        .then((result) => {
          // alert('Thank You! We reach you soon!');
          // Swal.fire(
          //     'Thank You!',
          //     'We will reach you soon',
          //     'success'
          //   );

          // navigate('https://thankyoupage.knsgroup.in/');
          window.location.href = 'https://thankyoupage.knsgroup.in/';

          setFormData({
            projectname: '',
            name: '',
            email: '',
            number: '',
          });

        }, (error) => {
          // alert('Server Issus! Not Submitted');
          Swal.fire(
            'Oops!',
            'Somethings Wrong, Please Try Again',
            'error'
          );
          setFormData({
            projectname: '',
            name: '',
            email: '',
            number: '',
          });
        });

    }
    else {
      Swal.fire(
        'Oops!',
        'Please Fill the details',
        'info'
      );
    }
    e.target.reset();

  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  return (
    <div className='backgrounter'>
      <div className='container p-5'>
        <form class="row g-3" onSubmit={sendEmail}>

          <div class="col-md-3">
            <label for="inputState" class="form-label text-light">Project name</label>
            <select id="inputState" name='project_name' class="form-select" value={formData.projectname} onChange={(event) => setFormData({ ...formData, projectname: event.target.value })}>
              <option value='' className='d-none'>Select Project</option>
              <option value='KNS AMORA'>KNS AMORA</option>
              <option value='KNS LAUREL'>KNS LAUREL</option>
              <option value='KNS ATHENA II'>KNS ATHENA II</option>
              <option value='KNS AADRIKA'>KNS AADRIKA</option>
              <option value='KNS CALISTO'>KNS CALISTO</option>
              <option value='KNS FRESCO'>KNS FRESCO</option>
              <option value='KNS AROHA'>KNS AROHA</option>
            </select>
          </div>

          <div class="col-md-3">
            <label for="inputEmail4" class="form-label text-light">Name</label>
            <input type="text" class="form-control " placeholder="First name" aria-label="First name" name='name' value={formData.name} onChange={(event) => setFormData({ ...formData, name: event.target.value })} />
          </div>
          <div class="col-md-3">
            <label for="inputEmail4" class="form-label text-light">Contact No</label>
            <input type="number" class="form-control hide-number-arrow " id="inputAddress" placeholder="Contact Number" name='number' value={formData.number} onChange={(event) => setFormData({ ...formData, number: event.target.value })} />
          </div>
          <div class="col-md-3">
            <label for="inputAddress" class="form-label text-light">Email</label>
            <input type="email" class="form-control " id="inputAddress" placeholder="Email Id" name='user_email' value={formData.email} onChange={(event) => setFormData({ ...formData, email: event.target.value })} />
          </div>
          <div class="form-check  ms-2">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange} />
            <label class="form-check-label text-light" for="flexCheckDefault">
              Accept all <Link className='text-dec' to='/privacy'>Terms</Link> & <Link className='text-dec' to='/privacy'>Conditions</Link>
            </label>
          </div>

          <div class="col-12">
            <button type="submit" class="btn button-color" disabled={!isChecked || isButtonDisabled} className={isChecked ? 'enabled' : 'disabled'} >Submit</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default FromFillup;