import React from 'react';
import './Faqcalisto.css';

const Faqcalisto = () => {
    return (
        <div className='container'>
            <div className='row text-center'>
                <h3>FAQ'S</h3>
                <div className='col-lg-4 col-sm-12 '>


                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                    How do I get in touch with you?
                                </button>
                            </h2>
                            <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body font">You can reach us at <a href="mailto:enquiry@knsgroup.in">enquiry@knsgroup.in</a> or call us at <a href="tel:+918880664433">8880664433</a>.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
                                    What locations are your projects situated at?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body font">In Bengaluru, our projects are spread across the city. In Mysuru, KNS Ethos is situated in Chamundipuram & KNS Calisto is situated at Kergalli-RT Nagar.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
                                    Do you have a loan facility?
                                </button>
                            </h2>
                            <div id="flush-collapseThree3" class="accordion-collapse collapse" aria-labelledby="flush-headingThree3" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body font">Yes, our projects are approved by most major public and private banks like SBI, HDFC, LIC HFL, ICICI, Dewan Housing, and Motilal Oswal.</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='col-lg-4 col-sm-12'>
                    <div class="accordion accordion-flush" id="accordionFlushExample2">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne4" aria-expanded="false" aria-controls="flush-collapseOne4">
                                    What is the starting price for a plotted development in Bangalore?
                                </button>
                            </h2>
                            <div id="flush-collapseOne4" class="accordion-collapse collapse" aria-labelledby="flush-headingOne2" data-bs-parent="#accordionFlushExample2">
                                <div class="accordion-body font">Plotted development in Bangalore starts from ₹20 Lakhs INR.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo5" aria-expanded="false" aria-controls="flush-collapseTwo5">
                                    How to check if a project is RERA registered?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo5" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo3" data-bs-parent="#accordionFlushExample2">
                                <div class="accordion-body font">To check the RERA registration status of a real estate project, you can follow these steps:<br /><br />
                                    • Go to the official website of the RERA authority of the state where the project is located. For example, if the project is in Karnataka, visit: <a href="https://rera.karnataka.gov.in/">https://rera.karnataka.gov.in/</a><br />
                                    • Look for the option to search for registered projects or developers. This option is usually located on the homepage of the website.<br />
                                    • Enter the required details such as the project’s name, the developer’s name, the registration number, or any other relevant details. Click on the search button or submit button.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree6" aria-expanded="false" aria-controls="flush-collapseThree6">
                                    Are You associated with any banks or lenders?
                                </button>
                            </h2>
                            <div id="flush-collapseThree6" class="accordion-collapse collapse" aria-labelledby="flush-headingThree4" data-bs-parent="#accordionFlushExample2">
                                <div class="accordion-body font ">Our projects are approved by most of the major public and private banks and financial institutions.</div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='col-lg-4 col-sm-12'>
                    <div class="accordion accordion-flush" id="accordionFlushExample3">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingSeven1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven7" aria-expanded="false" aria-controls="flush-collapseSeven7">
                                    What is the starting price for a plotted development in Mysore?
                                </button>
                            </h2>
                            <div id="flush-collapseSeven7" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven1" data-bs-parent="#accordionFlushExample3">
                                <div class="accordion-body font">Plotted development in Mysuru starts from ₹47 Lakhs INR.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingEight2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight8" aria-expanded="false" aria-controls="flush-collapseEight8">
                                    Are prices negotiable?
                                </button>
                            </h2>
                            <div id="flush-collapseEight8" class="accordion-collapse collapse" aria-labelledby="flush-headingEight2" data-bs-parent="#accordionFlushExample3">
                                <div class="accordion-body font">No, prices are not negotiable. Our projects are competitively priced.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingNine3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine9" aria-expanded="false" aria-controls="flush-collapseNine9">
                                    What does BMRDA, BDA, BIAPPA mean?
                                </button>
                            </h2>
                            <div id="flush-collapseNine9" class="accordion-collapse collapse" aria-labelledby="flush-headingNine3" data-bs-parent="#accordionFlushExample3">
                                <div class="accordion-body font">The above abbreviations stand for local approving authorities under which the project has been approved/registered. i.e., BDA - Bengaluru Development Authority, BMRDA - Bangalore Metropolitan Region Development Authority, BIAPPA - Bangalore International Airport Area Planning Authority, MUDA - Mysuru Urban Development Authority.</div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    );
};

export default Faqcalisto;