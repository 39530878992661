import React from 'react';
// import Backvideo from "../../../Assets/video.mp4";
// import Thinkimg from "../../../Assets/1.webp";
// import icon from "../../../Assets/2.png";

import './Home.css';
// import smallvideo from '../../../Assets/videosm.mp4';
import Offcanvas from '../../Offcanvas/Offcanvas';
import Patti from '../../Patti/Patti';
// import bigback from '../../../Assets/website backdesk.webp';
// import mobback from '../../../Assets/website backmob.webp';
import backone from '../../../Assets/back 1.jpg';
import backtwo from '../../../Assets/back 2.jpg';
import backthree from '../../../Assets/back 3.jpg';
import backonemob from '../../../Assets/back 1 mob.jpg';
import backtwomob from '../../../Assets/back 2 mob.jpg';
import backthreemob from '../../../Assets/back 3 mob.jpg';
import backaadrika from '../../../AssetsforProjects/aarika web back.webp';
import backaadrikamob from '../../../AssetsforProjects/aarika web mob.webp';
import backathena2 from '../../../AssetsforProjects/athenaback.webp';
import backathena2mob from '../../../AssetsforProjects/athenabackmob.webp';
import backlaurel from '../../../AssetsforIndividualProject/Laurel/laurelwebback.webp';
import backmoblaurel from '../../../AssetsforIndividualProject/Laurel/laurelmobback.webp';
import backananta from '../../../AssetsforIndividualProject/Laurel/webbackananta.webp';
import backmobananta from '../../../AssetsforIndividualProject/Laurel/webbackanantamob.webp';
import backamora from '../../../AssetsforIndividualProject/Amoraimages/amoradesktop.webp';
import backmoamora from '../../../AssetsforIndividualProject/Amoraimages/amoramobile.webp';
import { Link } from 'react-router-dom';


import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/autoplay'; 
import 'swiper/css/navigation';




const Home = () => {



   

    return (
        <div>
            <Offcanvas></Offcanvas>
            <Patti></Patti>
            {/* video import here */}
          <div className=" text-center carousel ">
            
                <div className='row'>
                <div className='col-lg-12'>


                    {/* Carousel is here */}
                    {/* <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                           <Link to='/calisto'><img src={backone} class="d-block w-100" alt="..."/></Link> 
                            </div>
                            <div class="carousel-item">
                           <Link to='/fresco'><img src={backthree} class="d-block w-100" alt="..."/></Link> 
                            </div>
                            <Link to='/aroha'> <div class="carousel-item">
                            <img src={backtwo}class="d-block w-100" alt="..."/>
                            </div></Link>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                        </div> */}
                

                <Swiper
                            modules={[Navigation, Autoplay]}
                            autoplay={true}
                            breakpoints={{
                                0:{
                                spaceBetween:10,      
                                slidesPerView: 1,
                                },
                                480: {
                                spaceBetween:10,
                                slidesPerView: 1,
                                },
                                768: {
                                spaceBetween:15,
                                slidesPerView: 1,
                                },
                                1024: {
                                spaceBetween:30,
                                slidesPerView:1,
                                },
                            }}
                            // navigation
                            pagination={{ clickable: true }}
                            className='image-slider text-center'

                            >
                                 <SwiperSlide> 
                                  <Link to='/amora'><img src={backamora} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/amora'><img src={backmoamora} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>  
                                 <SwiperSlide> 
                                  <Link to='/laurel'><img src={backlaurel} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/laurel'><img src={backmoblaurel} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>
                                <SwiperSlide> 
                                  <Link to='https://knsananta.com/'><img src={backananta} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='https://knsananta.com/'><img src={backmobananta} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>
                                
                                <SwiperSlide> 
                                  <Link to='/athena2'><img src={backathena2} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/athena2'><img src={backathena2mob} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>
                                <SwiperSlide> 
                                  <Link to='/aadrika'><img src={backaadrika} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/aadrika'><img src={backaadrikamob} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide> 
                                  <SwiperSlide> 
                                  <Link to='/calisto'><img src={backone} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/calisto'><img src={backonemob} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>  
                                    <SwiperSlide> 
                                  <Link to='/aroha'><img src={backtwo} className='w-100 desk ' alt='main background'/></Link>
                                  <Link to='/aroha'><img src={backtwomob} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide> 
                                    <SwiperSlide> 
                                  <Link to='/fresco'><img src={backthree} className='w-100 desk' alt='main background'/></Link>
                                  <Link to='/fresco'><img src={backthreemob} className='w-100 mob' alt='main background'/></Link>
                                    </SwiperSlide>    
                                        


                            </Swiper>

                    {/* <Link to='/alora'><img src={bigback} className='w-100 desk' alt='main background'/></Link>
                    <Link to='/alora'><img src={mobback} className='w-100 mob' alt='main background'/></Link> */}


                            {/* <video
                                autoPlay
                                loop
                                muted
                                playsinline
                                // controls={false}
                                // paused={false}
                                // playInBackground={true}
                                // playWhenInactive={true}
                                // ignoreSilentSwitch="ignore"
                                

                                className='w-100 h-100 video-setup'

                                style={{
                                    // position:"absolute",
                                    // width:"100%",
                                    // left: "50%",
                                    // top: "50%",
                                    // height: "100vh",
                                    // objectFit: "cover",
                                    // transform: "translate(-50%, -50%)",
                                    // zIndex: "-1", 
                                    
                                                 

                                }}
                                >
                                    <source src={Backvideo} type="video/mp4"/>
                                </video>
                                <video
                                autoPlay
                                loop
                                muted
                                playsinline
                                controls={true}
                                paused={false}
                                playInBackground={true}
                                playWhenInactive={true}
                                ignoreSilentSwitch="ignore"
                                

                                className='w-100 h-100 video-setuptwo'
                                >
                                   <source src={smallvideo} type="video/mp4"/>     
                                </video> */}

                                {/* <div className='position-absolute top-0 start-50 translate-middle-x  '>
                                    <img className="margin-think" src={Thinkimg} />
                                </div> */}
                </div>

                    </div>
          </div>






            {/* offcanvas here
            <button className="btn btn-none z-3 position-fixed top-0 start-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling ">
                <img className="wid-margin" src={icon} />                
            </button>

                <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header ">
                    <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                        <img className="knslogo-width" src={knslogo} /> 
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                <ul class="nav flex-column">
                        <li class="nav-item">
                            <a class="nav-link active p-3 m-3 text-decoration" aria-current="page" href="#">HOME</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link p-3 m-3 text-decoration" href="#">ABOUT</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link p-3 m-3 text-decoration" href="#">PROJECT</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link p-3 m-3 text-decoration" href="#">GALLERY</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link p-3 m-3 text-decoration" href="#">CAREERS</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link p-3 m-3 text-decoration" href="#"></a>
                        </li>
                </ul>
                </div>
                </div> */}

                {/* image import here */}

                


                {/* false div for making page layout */}

            <div  
            // className='w-100'    *************if requied pls enable      
            // style={{
            //     position:"relative",
            //     width:"100%",
            //     left: "50%",
            //     top: "50%",
            //     height: "100vh",
            //     objectFit: "cover",
            //     transform: "translate(-50%, -50%)",
            //     zIndex: "-1",
                

            // }}
            >

            </div>

               

        </div>
    );
};

export default Home;