import React from 'react';
import './AboutUs.css';
import word from '../../../Assets/4.webp';
import wordone from '../../../Assets/5.webp';
import sideimg from '../../../Assets/6.webp';

import shotimg from '../../../Assets/shotimg.gif';
import wordtwo from '../../../Assets/4mob.webp';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    return (
        
        // <div className='row mt-none '>
        //     <div className='col-12'>
        //             <div class="row">
        //                 <div class="col-12 bg-one text-start">
        //                  <img className="px-5 margin-setup " src={wordone} /> 
        //                 </div>                        
        //             </div>
        //             <div class="row">
        //                 <div class="col-lg-6 col-sm-12">
        //                     <img className="w-100 margintop margin-side" src={word} />


        //                <div className='text-start margin-side mt-5'>
        //                       {/* modal here */}
        //                 <button type="button" class=" button-color" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        //                    Know More
        //                 </button>
        //                 {/* Vertically centered modal  */}

                        
        //                 <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        //                 <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        //                     <div class="modal-content">
        //                     {/* <div class="modal-header">                                
                                
        //                     </div> */}
        //                     <div class="modal-body scroll ">  
        //                         <button type="button" class="btn-close position-absolute top-0 end-0 sizing-close" data-bs-dismiss="modal" aria-label="Close"></button>                                  
        //                          <img className="w-100 " src={modalimg} />                          
        //                     </div>                                     
        //                     </div>
        //                 </div>
        //                 </div>

        //                </div>



        //                 </div>
        //                 <div class="col-lg-6 col-sm-12 ">
        //                     <div className='d-flex flex-column justify-content-center ms-5'>
        //                     <img className="w-75 margin-minus" src={sideimg} />                        
        //                      <img className="margin-overloop img-over" src={shotimg} />
        //                     </div>                                          
        //                 </div>
                                               
        //             </div>
        //     </div>
        // </div>

        <div>
            <Helmet>
                <meta name='description' content='Do you want buy plots in Bangalore here the Most Trusted Plotted Developer in Bengaluru We Offering Premium Villa Plots and Residential sites'/>
                <link rel="canonical" href="https://knsgroup.in/about" />
            </Helmet>
           
            {/* div for every great story */}

            <div class="container-fluid ">
                <div class="row">
                    <div class="col-lg-12 bg-one d-flex justify-content-start">
                        <img className=" margin-setup pe-5 " src={wordone} alt='anirvan siteimage' />
                    </div>                    
                </div>               
                </div>

                {/* div for other demotration */}

                <div class="container ">                   
                    <div class="row">



                        {/* div for left side content */}


                        <div class="col-lg-6 col-md-12 col-sm-12  margin-maping mt-5">
                            <p className='fw-light font-sizeing'>
                            We believe every property purchase is just a beginning of an extraordinary story,
                            a story of achieving goals, realising one’s dreams, a story of a secure future 
                            and most of all a story of happiness at every squarefeet,<br/><br/>

                            at <span className='fw-bold'>KNS </span>, we acknowledge that story & the journey towards a secure future;
                            & this is the vision with which we strive to create some of the best products in class
                            and serve our customers exceptionally with diligence, transparency and on time delivery.<br/><br/>

                            <span className='fw-bold'>KNS </span>Infrastructure has been a pioneer in residential plotted development projects since 2007
                            with over 25 million squarefeet of developed & delivered layouts under our hat,
                            we are the avant-garde of the plotted development market




                            </p>



                                     {/* modal here */}
                        <Link type="button" class=" button-color margin-button " to='/about'>
                           Know More
                        </Link>


                         
                        

                     {/* div for right side content */}


                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center d-flex flex-column mt-5">
                             <div className='d-flex justify-content-center'>
                                 <img className="re-arrangeone" src={sideimg} />
                             </div>
                             <div  className='d-flex justify-content-center'>
                                <img className="re-arrange" src={shotimg} /> 
                            </div> 
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default AboutUs;